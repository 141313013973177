<template>
  <div class="pagination">
    <el-pagination
      layout="prev, pager, next"
      :page-size="pageSize"
      :current-page="startPage"
      @next-click="nextClick"
      @prev-click="prevClick"
      @current-change="currentChange"
      prev-text="上一页"
      next-text="下一页"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
    props:{
        pageSize:{
            type: [String, Number],
            default: 10
        }, // 每页显示个数选择器的选项设置
        startPage:{
            type: [String, Number],
            default: 1
        }, //默认第几页
        total:{
            type: [String, Number],
            default: 1
        }
    },
    data () {
        return {}
    },
    methods:{
        nextClick(size) {
            this.$emit('nextClick',size)
        },
        prevClick(size) {
            this.$emit('prevClick',size)
        },
        // 页码改变
        currentChange(size) {
            this.$emit('currentChange',size)
        }
    }
}
</script>

<style></style>
