import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import VueQuillEditor from 'vue-quill-editor'
import request from './api/request'
Vue.prototype.$request = request
import pagination from '@/components/pagintion.vue'
Vue.component('pagination',pagination)//初始化组件
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
 
Vue.use(VueQuillEditor, /* { default global options } */)
Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  components: {
    pagination
  },
  render: h => h(App)
})
