// 对本地存储封装
// 设置
function setLocal (key, value) {
    window.localStorage.setItem(key, value)
}
// 获取
function getLocal (key) {
    return window.localStorage.getItem(key)
}
// 删除
function removeLocal (key) {
    window.localStorage.removeItem(key)
}
export { setLocal, getLocal, removeLocal }