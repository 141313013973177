import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/homePage',
    component: () => import('@/views/homePage/homePage.vue'),
    redirect:'training/training-classification',
    meta: {
      title: '首页',
      needLogin:true,
      needToken:true
    },
    children:[{
      name: '首页',
      path: 'homePage',
      component: () => import('@/views/homePage/homePage.vue'),
      redirect:'training/training-classification',
      meta: {
        needLogin: true,
        needToken:true,
        title: '首页'
      }
    },{
      name: '培训考核',
      path: 'training/training-kh',
      component: () => import('@/views/homePage/training/training-kh.vue'),
      meta: {
        needLogin: true,
        needToken:true,
        title: '培训考核'
      }
    },{
      name: '题目添加',
      path: 'topic/addTopic',
      component: () => import('@/views/homePage/topic/addTopic.vue'),
      meta: {
        needLogin: true,
        needToken:true,
        title: '题目添加'
      }
    },{
      name: '考试详情',
      path: 'topic/testExam',
      component: () => import('@/views/homePage/topic/testExam.vue'),
      meta: {
        needLogin: true,
        needToken:true,
        title: '考试详情'
      }
    },{
      name: '培训资料',
      path: 'training/training-ziliao',
      component: () => import('@/views/homePage/training/training-ziliao.vue'),
      meta: {
        needLogin: true,
        needToken:true,
        title: '培训资料'
      }
    },{
      name: '资料分类',
      path: 'training/training-classification',
      component: () => import('@/views/homePage/training/training-classification.vue'),
      meta: {
        needLogin: true,
        needToken:true,
        title: '资料分类'
      }
    },{
      name: '添加新闻',
      path: 'training/training-addnews',
      component: () => import('@/views/homePage/training/training-addnews.vue'),
      meta: {
        needLogin: true,
        needToken:true,
        title: '添加新闻'
      }
    },{
      name: '添加视频',
      path: 'training/training-addVideo',
      component: () => import('@/views/homePage/training/training-addVideo.vue'),
      meta: {
        needLogin: true,
        needToken:true,
        title: '添加视频'
      }
    },{
      name: '科普宣教',
      path: 'training/training-kp',
      component: () => import('@/views/homePage/training/training-kp.vue'),
      meta: {
        needLogin: true,
        needToken:true,
        title: '科普宣教'
      }
    },{
      name: '用户管理',
      path: 'training/training-user',
      component: () => import('@/views/homePage/training/training-user.vue'),
      meta: {
        needLogin: true,
        needToken:true,
        title: '用户管理'
      }
    },{
      name: '问题反馈',
      path: 'training/training-feedback',
      component: () => import('@/views/homePage/training/training-feedback.vue'),
      meta: {
        needLogin: true,
        needToken:true,
        title: '问题反馈'
      }
    },{
      name: '测试',
      path: 'test/test',
      component: () => import('@/views/homePage/test/test.vue'),
      meta: {
        title: '测试'
      }
    }]
  }
]
// 前置守卫
// router.beforeEach((to, from, next) => {
//   // ...
// })
// 后置守卫
// router.afterEach((to, from) => {
//   // ...
// })
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  routes
})

export default router
