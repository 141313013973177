import axios from "axios";
import Vue from 'vue'
import ElementUI  from 'element-ui';
import router from '../router'
import { getLocal, removeLocal } from './local'
const _fetch = axios.create({
  // baseURL: 'http://train.localhost.com'// 本地
  baseURL: process.env.VUE_APP_URL   //线上
});

//请求拦截
_fetch.interceptors.request.use(
  config => {
    if (config.needToken) {
      config.headers.Authorization = getLocal("token");
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

//响应拦截
_fetch.interceptors.response.use(
  function(response) {
    if(response.data.code == 2) {
      ElementUI.Message.error('请重新登录！')
      removeLocal("token")
      setTimeout(()=>{
        router.push('/')
      },1000)
    }
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default _fetch;
